import classnames from 'classnames';
import * as React from 'react';
import { IPriceProps } from './Price.props';

/**
 * Price component
 * @extends {React.PureComponent<IPriceProps>}
 */
export default class Price extends React.PureComponent<IPriceProps> {
    public static defaultProps: Partial<IPriceProps> = {
        rangeFormatText: '{0} - {1}',
        originalPriceText: 'Original Price',
        currentPriceText: 'Current Price'
    };

    private static rangeRegex: RegExp = /(?:(\{0\})|(\{1\}))/gm;

    public render(): JSX.Element | null {
        // yield null on undefined result for enzyme
        return (this._showStrikethroughPricing() ? this._renderCurrentPriceWithOriginalPrice() : this._renderCurrentPrice(true)) || null;
    }

    private _showStrikethroughPricing(): boolean {
        const { originalPrice, currentPrice } = this.props;
        if (originalPrice && currentPrice) {
            const originalStartingPrice = originalPrice.startingValue;
            const currentStartingPrice = currentPrice.startingValue;
            const originalEndingPrice = originalPrice.endingValue ? originalPrice.endingValue : 0;
            const currentEndingPrice = currentPrice.endingValue ? currentPrice.endingValue : 0;

            return (originalStartingPrice > currentStartingPrice) ||
                (originalEndingPrice > currentEndingPrice);
        }

        return false;
    }

    private _renderCurrentPriceWithOriginalPrice(): JSX.Element | null {
        const { startingValue, endingValue } = this.props.originalPrice!;
        const initialPrice = this._formatCurrency(startingValue);
        const finalPrice = this._formatCurrency(endingValue);
        const priceRange = initialPrice && finalPrice ? this._formatPriceRange(initialPrice, finalPrice) : undefined;

        return (
            <span className={classnames('price', 'price-container', this.props.className || '')}>
                {priceRange && (
                    <React.Fragment>
                        <s className='price-strikethrough'>{priceRange}</s>
                        {this._renderCurrentPrice(false)}
                    </React.Fragment>
                )}
                {!priceRange && (
                    <React.Fragment>
                        <span className='price-text-screen-reader'> {this.props.originalPriceText} {initialPrice} {this.props.currentPriceText} {this._renderCurrentPrice(false)}</span>
                        <s className='price-strikethrough' aria-hidden='true'>{initialPrice}</s>
                        <span aria-hidden='true'>{this._renderCurrentPrice(false)}</span>
                    </React.Fragment>
                )}
                {this.props.savingsText && <span className='price-savings'>{this.props.savingsText}</span>}
            </span>
        );
    }

    private _renderCurrentPrice(includeClassName: boolean): JSX.Element | undefined {
        const { currentPrice, className } = this.props;
        const classNames = className && includeClassName ? [className] : [];
        const initialPrice = currentPrice ? this._formatCurrency(currentPrice.startingValue) : undefined;
        const finalPrice = currentPrice ? this._formatCurrency(currentPrice.endingValue as number) : undefined;
        const isRange = initialPrice && finalPrice;
        const priceRange = isRange ? this._formatPriceRange(initialPrice!, finalPrice!) : undefined;
        return isRange
            ? (priceRange && this._rangeHelper({ className: classNames.concat('price-range'), text: priceRange })) as JSX.Element
            : (initialPrice && this._rangeHelper({ className: classNames, text: initialPrice })) as JSX.Element;
    }

    private _rangeHelper({ className, text }: { className: string[]; text: string }): JSX.Element {
        return <span className={classnames(['price', 'price-actual'].concat(className))} itemProp='price'>{`  ${text || ''}`}</span>;
    }

    private _formatCurrency(price: number | undefined): string | undefined {
        if (price === undefined) {
            return undefined;
        }

        if (price === 0 && this.props.freePriceText) {
            return this.props.freePriceText;
        }

        if (this.props.currencyFormatter) {
            return this.props.currencyFormatter(price);
        }

        return new Intl.NumberFormat(
                this.props.locale,
                { style: 'currency', currencyDisplay: 'symbol', currency: this.props.currencyCode }).format(price);
    }

    private _formatPriceRange(initialPrice: string, finalPrice: string): string | undefined {
        if (!this.props.rangeFormatText!.match(Price.rangeRegex)) {
            return;
        }
        return this.props.rangeFormatText!.replace(Price.rangeRegex, (_: string, initial: string) => initial ? initialPrice : finalPrice);
    }
}
